//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import TextEditor from '@/components/tools/TextEditor.vue';

export default {
  components: { TextEditor },

  data() {
    return {
      headerLoading: false,
      footerLoading: false,
      headerContentJPN: '',
      footerContentJPN: '',
      headerContentENG: '',
      footerContentENG: '',
      headerContentVIE: '',
      footerContentVIE: '',
      tabOptions: [
        { key: 'JPN', label: 'Japanese' },
        { key: 'ENG', label: 'English' },
        { key: 'VIE', label: 'Vietnamese' }
      ]
    };
  },

  computed: {
    ...mapGetters('user', ['masterData', 'userInfo']),
    defaultHeaderJPN() {
      return this.masterData?.default_header_jpn || {};
    },
    defaultFooterJPN() {
      return this.masterData?.default_footer_jpn || {};
    },
    defaultHeaderENG() {
      return this.masterData?.default_header_eng || {};
    },
    defaultFooterENG() {
      return this.masterData?.default_footer_eng || {};
    },
    defaultHeaderVIE() {
      return this.masterData?.default_header_vie || {};
    },
    defaultFooterVIE() {
      return this.masterData?.default_footer_vie || {};
    },
    isActiveHeaderJPN() {
      return this.defaultHeaderJPN?.isActive ?? false;
    },
    isActiveHeaderENG() {
      return this.defaultHeaderENG?.isActive ?? false;
    },
    isActiveHeaderVIE() {
      return this.defaultHeaderVIE?.isActive ?? false;
    },
    isActiveFooterJPN() {
      return this.defaultFooterJPN?.isActive ?? false;
    },
    isActiveFooterENG() {
      return this.defaultFooterENG?.isActive ?? false;
    },
    isActiveFooterVIE() {
      return this.defaultFooterVIE?.isActive ?? false;
    }
  },

  watch: {
    masterData: {
      handler() {
        this.initData();
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('user', ['UpdateMasterData', 'AddMasterData']),
    initData() {
      this.headerContentJPN = this.defaultHeaderJPN?.value?.str_val || '';
      this.footerContentJPN = this.defaultFooterJPN?.value?.str_val || '';
      this.headerContentENG = this.defaultHeaderENG?.value?.str_val || '';
      this.footerContentENG = this.defaultFooterENG?.value?.str_val || '';
      this.headerContentVIE = this.defaultHeaderVIE?.value?.str_val || '';
      this.footerContentVIE = this.defaultFooterVIE?.value?.str_val || '';
    },
    getComputed(property) {
      return this[property];
    },
    changeHeaderContent(value, lang) {
      this[`headerContent${lang}`] = value;
    },
    changeFooterContent(value, lang) {
      this[`footerContent${lang}`] = value;
    },
    updateDefaultHeader(lang) {
      const data = this[`defaultHeader${lang}`];
      this.updateUserMasterData({
        type: 'headerLoading',
        notifyMessage: {
          success: this.$t('The default header is updated.'),
          error: this.$t('The default header update failed.')
        },
        payload: {
          field: `default_header_${lang.toLowerCase()}`,
          str_val: this[`headerContent${lang}`],
          ...(data.id ? { id: data.id } : { owner_id: this.userInfo?.company_id })
        }
      });
    },
    activeHeader({ target }, key) {
      this.updateIsActiveHeader(target.checked, key);
    },
    activeFooter({ target }, key) {
      this.updateIsActiveFooter(target.checked, key);
    },
    updateIsActiveHeader(value, lang) {
      const data = this[`defaultHeader${lang}`];
      this.updateUserMasterData({
        type: 'headerLoading',
        notifyMessage: {
          success: this.$t(`This default header is ${value ? 'activated' : 'de-activated'}.`),
          error: this.$t('The default header update failed.')
        },
        payload: {
          is_active: value,
          ...(data.id
            ? { id: data.id }
            : {
                field: `default_header_${lang.toLowerCase()}`,
                str_val: '',
                owner_id: this.userInfo?.company_id
              })
        }
      });
    },
    updateIsActiveFooter(value, lang) {
      const data = this[`defaultFooter${lang}`];
      this.updateUserMasterData({
        type: 'footerLoading',
        notifyMessage: {
          success: this.$t(`This default footer is ${value ? 'activated' : 'de-activated'}.`),
          error: this.$t('The default footer update failed.')
        },
        payload: {
          is_active: value,
          ...(data.id
            ? { id: data.id }
            : {
                field: `default_footer_${lang.toLowerCase()}`,
                str_val: '',
                owner_id: this.userInfo?.company_id
              })
        }
      });
    },
    updateDefaultFooter(lang) {
      const data = this[`defaultFooter${lang}`];
      this.updateUserMasterData({
        type: 'footerLoading',
        notifyMessage: {
          success: this.$t('The default footer is updated.'),
          error: this.$t('The default footer update failed.')
        },
        payload: {
          field: `default_footer_${lang.toLowerCase()}`,
          str_val: this[`footerContent${lang}`],
          ...(data.id ? { id: data.id } : { owner_id: this.userInfo?.company_id })
        }
      });
    },
    async updateUserMasterData({ type, notifyMessage, payload }) {
      try {
        this[type] = true;
        if (payload.id) {
          const data = await this.$s.api.masterData.updateMasterDataById({
            id: payload.id,
            payload
          });
          this.UpdateMasterData(data);
        } else {
          const data = await this.$s.api.masterData.addMasterData(payload);
          this.AddMasterData(data);
        }
        this.$notification.success({ message: notifyMessage.success });
      } catch (error) {
        console.log(error);
        this.$notification.error({ message: notifyMessage.error });
      } finally {
        this[type] = false;
      }
    }
  }
};
