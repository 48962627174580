var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-tabs",
    { attrs: { type: "card" } },
    _vm._l(_vm.tabOptions, function(item) {
      return _c(
        "a-tab-pane",
        { key: item.key, attrs: { tab: _vm.$t(item.label) } },
        [
          _c(
            "div",
            { staticStyle: { background: "#ececec", padding: "30px" } },
            [
              _c(
                "a-card",
                { attrs: { title: _vm.$t("Default Header"), bordered: false } },
                [
                  [
                    _c(
                      "a-checkbox",
                      {
                        attrs: {
                          checked: _vm.getComputed("isActiveHeader" + item.key)
                        },
                        on: {
                          change: function(value) {
                            return _vm.activeHeader(value, item.key)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Active")) + " ")]
                    ),
                    _c("br"),
                    _c("span", { staticClass: "note-text-style" }, [
                      _vm._v(
                        " (" +
                          _vm._s(
                            _vm.$t(
                              "In case of active, this default header will be used for the new page of this account, otherwise it will use the system's default header contents."
                            )
                          ) +
                          ") "
                      )
                    ]),
                    _c("br"),
                    _c("br"),
                    _c("text-editor", {
                      key: "editor-1",
                      staticStyle: { width: "100%" },
                      attrs: {
                        type: "header",
                        placeholder: _vm.$t("Please input your header"),
                        projectId: "common",
                        projectLang: "jpn",
                        content: _vm.$data["headerContent" + item.key]
                      },
                      on: {
                        change: function(value) {
                          return _vm.changeHeaderContent(value, item.key)
                        }
                      }
                    }),
                    _c("br"),
                    _c(
                      "div",
                      { staticStyle: { "text-align": "right" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              icon: "save",
                              disabled:
                                _vm.$data["defaultHeader" + item.key] &&
                                _vm.$data["defaultHeader" + item.key].value ==
                                  _vm.$data["headerContent" + item.key],
                              loading: _vm.headerLoading
                            },
                            on: {
                              click: function() {
                                return _vm.updateDefaultHeader(item.key)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              ),
              _c("br"),
              _c(
                "a-card",
                { attrs: { title: _vm.$t("Default Footer"), bordered: false } },
                [
                  [
                    _c(
                      "a-checkbox",
                      {
                        attrs: {
                          checked: _vm.getComputed("isActiveFooter" + item.key)
                        },
                        on: {
                          change: function(value) {
                            return _vm.activeFooter(value, item.key)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("Active")) + " ")]
                    ),
                    _c("br"),
                    _c("span", { staticClass: "note-text-style" }, [
                      _vm._v(
                        " (" +
                          _vm._s(
                            _vm.$t(
                              "In case of active, this default footer will be used for the new page of this account, otherwise it will use the system's default footer contents."
                            )
                          ) +
                          ") "
                      )
                    ]),
                    _c("br"),
                    _c("br"),
                    _c("text-editor", {
                      key: "editor-2",
                      attrs: {
                        type: "footer",
                        placeholder: _vm.$t("Please input your footer"),
                        projectId: "common",
                        projectLang: "jpn",
                        content: _vm.$data["footerContent" + item.key]
                      },
                      on: {
                        change: function(value) {
                          return _vm.changeFooterContent(value, item.key)
                        }
                      }
                    }),
                    _c("br"),
                    _c(
                      "div",
                      { staticStyle: { "text-align": "right" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: {
                              icon: "save",
                              disabled:
                                _vm.$data["defaultFooter" + item.key] &&
                                _vm.$data["defaultFooter" + item.key].value ==
                                  _vm.$data["footerContent" + item.key],
                              loading: _vm.footerLoading
                            },
                            on: {
                              click: function() {
                                return _vm.updateDefaultFooter(item.key)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                        )
                      ],
                      1
                    )
                  ]
                ],
                2
              )
            ],
            1
          )
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }